import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./components/pages/home-two/home-two.module").then(
        (m) => m.HomeTwoModule
      ),
    data: { breadcrumb: "Inicio", description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  // Services
  {
    path: "servicios",
    loadChildren: () =>
      import("./components/pages/services/services.module").then(
        (m) => m.ServicesModule
      ),
    data: { breadcrumb: "Servicios", description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  {
    path: "servicio/:id",
    loadChildren: () =>
      import("./components/pages/service-details/service-details.module").then(
        (m) => m.ServiceDetailsModule
      ),
    data: { breadcrumb: "Service Details", description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  // Doctors
  {
    path: "sobre-mi",
    loadChildren: () =>
      import("./components/pages/doctor-details/doctor-details.module").then(
        (m) => m.DoctorDetailsModule
      ),
    data: { breadcrumb: "Sobre Mi" , description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  // Contact
  {
    path: "contacto",
    loadChildren: () =>
      import("./components/pages/contact/contact.module").then(
        (m) => m.ContactModule
      ),
    data: { breadcrumb: "Contacto", description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  // Extra
  {
    path: "error-page",
    loadChildren: () =>
      import("./components/pages/error-page/error-page.module").then(
        (m) => m.ErrorPageModule
      ),
    data: { breadcrumb: "Error 404" , description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
  {
    path: "**",
    loadChildren: () =>
      import("./components/pages/error-page/error-page.module").then(
        (m) => m.ErrorPageModule
      ),
    data: { breadcrumb: "Error 404" , description: "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico." },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
