import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title, private breadcrumbService: BreadcrumbService,private meta: Meta) {
  }
  
  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      console.log(crumbs)
      this.titleService.setTitle(this.createTitle(crumbs));
      this.meta.updateTag({ name: 'description', content: this.createDescription(crumbs) as string });
    });
  }
  onActivate(_event:any){
    window.scroll(0,0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = "Dr. William Raymundo";
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private createDescription(routesCollection: Breadcrumb[]) {

    if(routesCollection.length > 0) {
      return routesCollection.map((route) => {
        const dataRoute = route.route?.data;
        if(dataRoute) {
          return dataRoute?.description
        } else {
          return "";
        }
    })[0];
    } else {
      return "Bienvenido al sitio web del Dr. William Raymundo Brindo atención médica integral con servicios de ingresos hospitalarios, electrocardiograma, terapia respiratoria, consulta a domicilio, certificaciones médicas y cuidado especializado para el adulto mayor**. Mi compromiso es ofrecer una atención profesional, cercana y de calidad para el bienestar de nuestros pacientes. Agenda tu cita hoy y recibe el mejor cuidado médico."
    }
  }

  private titlesToString(titles: any[]) {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }
}
